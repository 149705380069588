import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInjector } from './modules/main/services/app-injector.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentService } from 'services/content.service';
import { SiteConfigService } from 'services/site-config.service';
import { DatadogIntegration } from '@main/third-party-integration';
import { environment } from 'environment';
import posthog from 'posthog-js';

DatadogIntegration.initializeDatadog();

posthog.init(
	environment.posthog.key,
	{
		api_host: environment.posthog.host,
		person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
		capture_pageview: false,
		capture_pageleave: true,
	}
);

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [
        // Core Modules
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        // 3rd-party Modules
        NgbModalModule,
        // App Modules
        AppRoutingModule,
        CoreModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppCustomLogic,
            multi: true,
            deps: [SiteConfigService, ContentService],
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class AppModule {
	constructor(injector: Injector) {
		AppInjector.injector = injector;
	}
}

export function initializeAppCustomLogic(siteConfigService: SiteConfigService, contentService: ContentService): () => Promise<void> {
	return async () => {
		await siteConfigService.init();
		contentService.setupSiteLanguages(siteConfigService.site.languages ?? []);
	};
}
